/* Updated CSS with faster animations */
.venturebutton {
    position: relative;
    padding: 1.7em 3em;
    outline: none;
    border: 1px solid #303030;
    background: #212121;
    color: #ae00ff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    overflow: hidden;
    transition: 0.2s;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
}
   
.venturebutton:hover {
    box-shadow: 0 0 5px #ae00ff, 0 0 18px #001eff, 0 0 25px #ae00ff;
    transition-delay: 0.3s; /* Adjusted for overall hover effect */
}

.venturebutton span {
    position: absolute;
}

.venturebutton span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ae00ff);
}

.venturebutton:hover span:nth-child(1) {
    left: 100%;
    transition: 0.4s; /* Made faster */
}

.venturebutton span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #001eff);
}

.venturebutton:hover span:nth-child(3) {
    right: 100%;
    transition: 0.4s; /* Made faster */
    transition-delay: 0.175s; /* Adjusted delay */
}

.venturebutton span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ae00ff);
}

.venturebutton:hover span:nth-child(2) {
    top: 100%;
    transition: 0.4s; /* Made faster */
    transition-delay: 0.1s; /* Adjusted delay */
}

.venturebutton span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #001eff);
}

.venturebutton:hover span:nth-child(4) {
    bottom: 100%;
    transition: 0.4s; /* Made faster */
    transition-delay: 0.26s; /* Adjusted delay */
}

.venturebutton:active {
    background: #ae00af;
    background: linear-gradient(to top right, #ae00af, #001eff);
    color: #bfbfbf;
    box-shadow: 0 0 8px #ae00ff, 0 0 6px #001eff, 0 0 6px #ae00ff;
    transition: 0.1s;
}

.venturebutton:active span {
    transition: none;
    transition-delay: none;
}
