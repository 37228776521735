.page-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #251627;
  min-height: 10vh;
  padding: 0 10px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the container spans the full width */
  max-height: 100px;
}

/* Apply order properties */
.brand {
  order: 3; /* Logo appears last on the right */
}

nav {
  order: 2; /* Navigation routes appear in the middle */
}

.ButtonSM { /* Assuming ButtonSM is a class you can target. If not, add a class or target the element differently */
  order: 1; /* ButtonSM appears first on the left */
}

.brand img {
  width: 40px;
  height: auto;
}

.menu-content {
  position: fixed;
  left: -300px;
  top: 0;
  width: 300px;
  height: 100vh;
  background-color: #e6e1c5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease;
}

.nav-route {
  margin: 0 10px; /* Adjust spacing around each route as needed */
  cursor: pointer;
  background: none;
  border: none;
  color: var(--Pearl-container-color); /* Assuming white text on your header background */
  font-size: 1.5em; /* Adjust for your preferred size */
  transition: text-shadow 0.3s ease;
}

.nav-routes {
  display: flex;
}

/* Styles for the active route with a reduced "lit up" effect */
.active {
  text-shadow: 0 0 4px var(--Pearl-container-color), 0 0 6px var(--Pearl-container-color); /* More subtle effect */
}

.nav-route:hover,
.active:hover {
  text-shadow: 0 0 4px var(--Pearl-container-color); /* Optional: Subtle hover effect */
}

