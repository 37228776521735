@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --Pearl-container-color: #fff8e8;
  --Lavender-button-color: #a776ec;
  --Header-russian-Violet: #2a163c;
  --Timberwolf: #dedbd2;
  --Linen: #f7ece1;
  --Font: #0a0b0a;
  font-family: Lato, Palanquin, Poppins;
}

body {
  margin: 0;
  background-color: var(--Background-Raisin-Black) !important;
  -webkit-font-smoothing: antialiased;
  background: hsla(268, 41%, 16%, 1);
  background: linear-gradient(90deg, hsla(268, 41%, 16%, 1) 17%, hsla(265, 76%, 69%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(268, 41%, 16%, 1) 17%, hsla(265, 76%, 69%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(268, 41%, 16%, 1) 17%, hsla(265, 76%, 69%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#28183a", endColorstr="#a776ec", GradientType=1 );
}

h1 { 
  font-size: 36px;
}

h2 {
  font-size: 26px;
}
.page-header {
  background-color: var(--Header-russian-Violet);
  border-bottom: 2px solid oldlace;
  text-align: center;
  max-height: 42px;
  padding: 0;
}

