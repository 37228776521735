
.map-competitors {
    display: flex;
    position: relative;
    width: 95%;
    height: 425px;
    background-color: var(--Pearl-container-color);
    border-radius: 6px;
    margin-top: 15px;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  #map {
    position: relative;
    width: 95%;
    height: 90%;
    flex: 2;
    margin-left: 10px;
    border: 2px var(--Font);
  }
  
  .leaflet-top,
  leaflet-bottom {
    z-index: 500;
  }
  
  .competitors-list {
    flex: 1; /* List takes up less space */
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    border-color: var(--Font);
    border-left: 2px solid;
    overflow-y: auto;
    max-height: 400px;
  }
  
  .competitors {
    list-style: none;
    padding: 0;
  }
  
  .competitors li {
    text-align: right; /* Align list items text to the right */
    margin: 0 0 10px;
    padding: 5px;
  }
  
  