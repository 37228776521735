.idealcustomercontainer {
    background-color: var(--Pearl-container-color);
    border-radius: 6px;
    margin-top: 15px;
    flex-wrap: wrap;
}

.idealcustomercontainer > h1 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 15px;
}

.idealcustomercontainer > h2 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 25px;
    font-size: medium;
    padding-left: 0%;
}

.descriptionwrapper {
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    padding: 1em;
}


.descriptionwrapper > p {
    color: #2a163c;
    text-align: center;
    font-size: medium;
    padding-left: 0%;
    margin: 10px 0 10px 0;
    max-width: 20%;
}

.buttonwrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 35px;
}

