.costoutlook {
    width: 95%;
    height: 425px;
    background-color: var(--Pearl-container-color);
    border-radius: 6px;
    margin-top: 20px;
    padding: 10px;
}

.costoutlook > h1 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 15px;
}

.costoutlook > h2 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 25px;
    font-size: medium;
    padding-left: 0%;
}

.descriptionwrapper > p {
    color: #2a163c;
    text-align: center;
    font-size: medium;
    padding-left: 0%;
    margin: 10px 0 10px 0;
    max-width: 33%;
}

.buttonwrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    justify-content: space-around;
}

.descriptionwrapper {
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    padding: 1em;
}

