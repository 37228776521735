.buttonSM{
    border: 3px solid #D2B8F6;
    background-color: var(--Lavender-button-color);
    border-radius: 6px;
    color: var(--Pearl-container-color);
    z-index: 700;
    font-weight: 600;
    transition: transform .2s ease-in-out;
    padding: .25rem .75rem
  }
  
  .buttonSM:hover {
    transform: scale(1.1);
  }