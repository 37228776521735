.get_started_button {
    position: relative;
    padding: 12px 35px;
    background: white;
    font-size: 17px;
    font-weight: 800;
    color: var(--Lavender-button-color);
    border: 3px solid white;
    border-radius: 8px;
    box-shadow: 0 0 0 var(--Lavender-button-color);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .star-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  
  .star-2 {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-5 {
    position: absolute;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
  }
  
  .star-6 {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 var(--Lavender-button-color));
    z-index: -5;
    transition: all 0.8s ease;
  }
  
  .get_started_button:hover {
    background: transparent;
    color: white;
    box-shadow: 0 0 25px var(--Lavender-button-color);
  }
  
  .get_started_button:hover .star-1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .get_started_button:hover .star-2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .get_started_button:hover .star-3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .get_started_button:hover .star-4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .get_started_button:hover .star-5 {
    position: absolute;
    top: 25%;
    left: 115%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .get_started_button:hover .star-6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px var(--Lavender-button-color));
    z-index: 2;
  }
  
  .fil0 {
    fill: var(--Lavender-button-color);
  }
  