.login_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
@media only screen and (max-width: 515px) {
  .login_body{
    transform: scale(0.70);
}
}
.login_body {
  display: flex;
  justify-content: center;
  width: 500px;
  flex-direction: column;
  border: 4px transparent solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 20px;
  padding: 50px;
  background-color: rgba(87, 50, 92, 0.5);
  backdrop-filter: blur(3px);

}

.login_header {
  display: flex;
  justify-content: center;
  color: #fff8e8;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 34px;
}

.login_form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.login_form input {
  width: 100%;
  font-size: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid lightslategray;
  /* Keep the initial border as 1px */
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  margin-bottom: 10px;
}

.login_form input[type=text] {
  font-family: inherit;
}
 

.login_form input:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0 0 0 1px lightslategray;
  /* Add an extra layer of box-shadow to simulate the border */
}

.login_form input:focus {
  border: 3px solid lightslategray;
}


.google_button {
  background-color: #fff8e8;
  display: flex;
  border: 2px solid black;
  border-radius: 6px;
  font-family: Roboto;
  transition: transform 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.google_button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.google_button > img {
  width: 30px;
  
}

.google_button > p {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 10px;
  margin-bottom: 0;
}

.eye_icon {
  width: 30px;
  position: absolute;
  right: 8px;
  top: 112px;
}

.eye_icon:hover {
  cursor: pointer;
}

