.feasibilitycontainer {
    display: flex;
    position: relative;
    background-color: var(--Pearl-container-color);
    flex-wrap: wrap;
    text-align: center;
  }

.feasibilityslider {
    width: 100%;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: grey;
}

.carousel-control-next, .carousel-control-prev {
    width: 7.5%;
    top: 500px;
    bottom: 50px;
}

.carousel-indicators [data-bs-target] {
    background-color: black;
}


.feasibilitycontent {
    position: relative;
    background-color: var(--Pearl-container-color);
}

.feasibilitycontent > h1 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 15px;
}

.feasibilitycontent > h2 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 25px;
    font-size: medium;
    padding-left: 0%;
}

.feasibilitystudy > h1 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 15px;
}

.feasibilitystudy > h2 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 25px;
    font-size: medium;
    padding-left: 0%;
}
  
.feasibilityScale {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.feasibilityScale > h1 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 15px;
}

.feasibilityScale > h2 {
    color: #2a163c;
    text-align: center;
    margin-bottom: 25px;
    font-size: medium;
    padding-left: 0%;
}
  

.feasibilityindicator {
    width: 400px;
    height: 425px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
  
#meter {
    transform: rotateX(180deg);
}
  
#meter_needle {
    position: absolute;
    z-index: 1;
    left: 21%;
    top: 43.6%;
    height: 150px;
    transform-origin: 80.6% 15.8%;
    transition: transform 2s ease-in-out;
}

.buttonwrapperContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 70px;
}

.buttonwrapperStudy {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 120px;
    flex-wrap: wrap;
}

.buttonwrapperStudy > div {
    padding: 1em;
}