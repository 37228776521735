.spacebarbutton {
    padding: 0.1em 0.20em;
    width: 18em;
    height: 4.2em;
    background-color: #212121;
    border: 0.08em solid #fff;
    border-radius: 0.3em;
    font-size: 12px;
    cursor: pointer;
}

.spacebarbutton span {
position: relative;
display: flex;
justify-content: center;
align-items: center;
bottom: 0.4em;
height: 2.5em;
background-color: #212121;
border-radius: 0.2em;
font-size: 1.5em;
color: #fff;
border: 0.08em solid #fff;
box-shadow: 0 0.4em 0.1em 0.019em #fff;
}

.spacebarbutton span:hover {
transition: all 0.2s;
transform: translate(0, 0.4em);
box-shadow: 0 0 0 0 #fff;
}

.spacebarbutton span:not(hover) {
transition: all 0.2s;
}

.spacebarbutton span:active {
  background-color: #fff;
  color: #212121;
}