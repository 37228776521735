.menu_page-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the header is above other content */
  background-color: #fff; /* Optional: Background color of the header */
  min-height: 5vh; /* Optional: Padding for the header */
  padding: 0 10px;
  border-bottom: 1px solid #000;
  background-color: #311432;
}

.menu_header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  flex-wrap: nowrap;
  max-height: 100px;
}

.menu_brand {
  order: 1;
  margin-right: 0;
}

.menu_brand img {
  width: 40px;
  height: auto;
  margin-left: auto;
}

.main_menu-content {
  position: fixed;
  left: -300px; /* Initially hidden */
  top: 0;
  width: 300px; /* Adjust the width as needed */
  height: 100vh;
  background-color: #e6e1c5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Styles for the active button */
.activeHeader {
  color: white;
  background-color: #007bff; /* Bootstrap primary blue, as an example */
  border-color: #007bff;
}

/* Additional styling for hover effect on buttons */
.nav-button:hover,
.activeHeader:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
