


/*

/* Ensure the message container starts from the top
.messageContainer {
  max-height: 100vh; /* Adjust based on your needs
  overflow-y: auto;
  padding: 10px;
  margin-top: 0; /* Make sure there is no top margin pushing it down
}

/* Ensure there's no extra padding or margin on the main container that might cause an issue
.ventureChatContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #251627;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0; /* Remove any padding that might be causing issues
  margin: 0; /* Remove any margin that might be causing issues 
}

  
  .message {
    background-color: #36393f;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .messageRole {
    font-weight: bold;
    margin-bottom: 5px;
    color: #fff;
  }
  
  .loadingMessage {
    color: #fff;
    text-align: center;
  }
  
  .inputContainer {
    display: flex;
    padding: 10px;
    background-color: #40444b;
  }
  
  .textInput {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
  }
  
  .sendButton {
    padding: 10px 20px;
    background-color: #7289da;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sendButton:hover {
    background-color: #677bc4;
  }
  */
  .create_venture_main {
    display: flex;
    flex-direction: column;
  }
  

  .label {
    color: white;
  }
  .survey-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    border: 2px solid;
    background-color: rgba(87, 50, 92, 0.5);
    padding: 30px;
  }
  
  .survey_form_border {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;

  }
  .question {
    margin-bottom: 20px;
  }
  
  .options label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .options input[type="radio"] {
    margin-right: 10px;
  }

  .radio-option input[type="radio"]:checked + label {
    border: 2px solid blue; /* Use any color you want */
    box-shadow: 0 0 0 3px rgba(0,0,255,0.1); /* Optional: add a glow effect */
  }

  .radio-option input[type="radio"]:checked + label {
    color: blue;
  }
  
  .radio-option {
    padding: 5px;
    border: 2px solid transparent; /* Default state with a transparent border */
    border-radius: 4px; /* Assuming you want rounded corners */
  }
  .input_box {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .venture_submit_button {
    padding: 10px 20px;
    background-color: #311432;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .venture_submit_button:hover {
    background-color: #572459;
  }
  