.main_menu {
  /* Enable scroll snapping */
  scroll-snap-type: y mandatory;
  /* Ensures the container allows vertical scrolling */
  overflow-y: scroll;
  scroll-padding-top: 8vh;

  height: 100vh;
}

.main_menu > div {
  padding-top: 10px;
  margin: 0px 15px;
  margin-top: 5vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  
  /* Apply scroll snap alignment */
 
  scroll-snap-align: start;
}

.name_back_arrow {
  z-index: 30;
  filter: invert(1);
  width: 60px;
  position: absolute;
  top: 5px;
  left: 5px;
  transition: transform 0.2s ease-in-out;
}

.name_input {
  width: 65%;
  font-size: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid lightslategray;
  /* Keep the initial border as 1px */
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  margin-bottom: 10px;
}

.name_input:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0 0 0 1px lightslategray;
  /* Add an extra layer of box-shadow to simulate the border */
}

.name_input:focus {
  border: 3px solid lightslategray;
}

.name_back_arrow:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.container_body {
  background-color: #251627;
  height: 95%;
  border-radius: 20px;
}

.venture_container_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #251627;
  height: 95%;
  border-radius: 20px;
}

.create_venture_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 50%;
  border: 2px;
  padding: 10px 20px;
  background-color: rgba(87, 50, 92, 0.5);
  border-radius: 20px;
}

.container_body > h1,
h2,
p {
  padding-left: 20px;
  color: #fff8e8;
}

.venture_bank_buttons {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 80%;
  margin-left: 40px;
  justify-content: space-around;
  
}

.venture_bank_buttons > div {
  padding: 50px;
  height: 100%;
  position: relative;
}

.venture_buttons:hover {
  transform: scale(1);
}




.venture_dropdown {
  position: absolute;
  z-index: 1;
  top: 55px; /* Position at the top */
  right: 55px; /* Position at the right */
  transform: scale(0.7);

}
.main_menu > div > h1 {
  color: #fff8e8;
}

.main_menu > div > h2 {
  color: #fff8e8;
}

.main_menu > div > p {
  color: #fff8e8;
}
