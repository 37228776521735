.signup_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: transparent;
  overflow: hidden;
}

@media only screen and (max-width: 515px) {
  .signup_body{
    transform: scale(0.7);
}
}
.signup_body {
  position: relative;
  display: flex;
  justify-content: center;
  width: 500px;
  flex-direction: column;
  border: 4px transparent solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 20px;
  padding: 50px;
  background-color: rgba(87, 50, 92, 0.5);
  backdrop-filter: blur(3px);
}

.signup_header {
  display: flex;
  justify-content: center;
  color: #fff8e8;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 34px;
  
}

.signup_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.signup_form input {
  width: 100%;
  font-size: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid lightslategray;
  /* Keep the initial border as 1px */
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  margin-bottom: 10px;
}

.signup_form input:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0 0 0 1px lightslategray;
  /* Add an extra layer of box-shadow to simulate the border */
}

.signup_form input:focus {
  border: 3px solid lightslategray;
}

.back_arrow > img {
  filter: invert(88%) sepia(3%) saturate(1577%) hue-rotate(333deg) brightness(112%) contrast(101%);
  width: 50px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.back_arrow > img:hover {
  cursor: pointer;
}


.eye_icon2 {
  width: 30px;
  position: absolute;
  top: 162px;
  right: 60px;


}

.eye_icon2:hover {
  cursor: pointer;
}