.interface_main {
    /* Enable scroll snapping */
    scroll-snap-type: y mandatory;
    /* Ensures the container allows vertical scrolling */
    overflow-y: scroll;
    height: 100vh;
    /* Add top padding to the scroll container */
    scroll-padding-top: 14vh; /* Adjust this value to get the desired space */
}
  
.center-container > div > * {
    height: 83vh;
    width: 97vw;
    margin-left: 20px;
    scroll-snap-align: start;
    margin-top: 60px;
    padding: 0;
}