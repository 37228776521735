.chat-input-container {
    background-color: var(--Pearl-container-color);
    border-radius: 6px;
    padding: 10px;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
  }
  .message-bubble {
    color: white;
    padding: 9px;
    border-radius: 15px;
    margin-bottom: 7px;
    max-width: 35%;
  }
  
  .message-user {
    font-weight: bold;
  }
  
  .message-bubble.User {
    background-color: #007bff; /* Blue background for user messages */
    margin-left: auto;
    margin-right: 4px;
  }
  
  .message-bubble.Guru {
    background-color: #28a745; /* Green background for Guru messages */
    margin-right: auto;
    margin-left: 4px;
  }
  
  .input-container {
    display: flex;
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
  }
  
  #textInputField {
    width: 275px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-left: 3px;
  }

  .chatfeed {
    display: flex;
    flex-direction: column; /* Reverse the order of chat bubbles */
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrolling */
    max-height: 90%;
  }
  